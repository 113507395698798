import React from 'react';
import './StatusCategory.scss';
import {ServiceStatus} from "../../Model/ServiceStatus";
import {Status} from "../../Model/Status";

class StatusCategory extends React.Component {

    state: { error: any | null, serviceStatus: ServiceStatus | null } = {
        error: null, isLoaded: false, serviceStatus: null
    };
    intervalID;

    categoryCode: string;
    categoryTitle: string;
    categoryDescription: string;
    isSubCategory: boolean = false;
    isDummy: boolean = false;


    constructor(props) {
        super(props);


        this.categoryCode = props.categoryCode;
        this.categoryTitle = props.categoryTitle;
        this.categoryDescription = props.categoryDescription;
        this.isSubCategory = props.isSubCategory;
        this.isDummy = props.isDummy;
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(_, prevstate) {
        // if (prevstate.showItem !== this.state.showItem) {
        //Tooltip.rebuild();
        // }
    }

    componentWillUnmount() {
        /*
          stop getData() from continuing to run even
          after unmounting this component. Notice we are calling
          'clearTimeout()` here rather than `clearInterval()` as
          in the previous example.
        */
        clearTimeout(this.intervalID);
    }

    millisToMinutesAndSeconds(milliseconds: number): string {
        let seconds = Math.floor(milliseconds / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);

        seconds = seconds % 60;
        minutes = minutes % 60;

        // 👇️ If you don't want to roll hours over, e.g. 24 to 00
        // 👇️ comment (or remove) the line below
        // commenting next line gets you `24:00:00` instead of `00:00:00`
        // or `36:15:31` instead of `12:15:31`, etc.
        hours = hours % 24;

        if (hours > 0) {
            return `${this.padTo2Digits(hours)}h ${this.padTo2Digits(minutes)}m`;
        }

        return `${this.padTo2Digits(minutes)}m ${this.padTo2Digits(seconds)}s`;


    }

    padTo2Digits(num: number): string {
        return num.toString().padStart(2, '0');
    }

    getData = () => {

        if (this.isDummy) {
            return;
        }
        this.setState({
            isLoading: true, error: null, serviceStatus: this.state.serviceStatus
        });

        // fetch("https://status.attlaz.com/backend/service-statuses/" + this.categoryCode + '?days=90')
        //     .then(res => res.json())
        //     .then((result) => {
        //             // console.log(result);
        //             if (result === null || result === undefined) {
        //                 console.error('Unable to load category ' + this.categoryCode);
        //                 this.setState({
        //                     isLoading: false, error: new Error('Unable to load')
        //                 });
        //             } else {
        //
        //                 const serviceStatus: ServiceStatus = new ServiceStatus();
        //
        //                 serviceStatus.fetched = new Date(result.fetched);
        //                 serviceStatus.statusText = result.statusText;
        //                 serviceStatus.status = result.status;
        //                 for (let rawStatus of result.statuses) {
        //                     const status: Status = new Status(rawStatus.status, new Date(rawStatus.date), rawStatus.message);
        //                     status.issues = [];
        //                     status.latestStatus = rawStatus.latest_status;
        //                     for (let rawStatusIssue of rawStatus.issues) {
        //
        //                         status.issues.push(new StatusIssue(rawStatusIssue.id, new Date(rawStatusIssue.start), new Date(rawStatusIssue.end), rawStatusIssue.status, rawStatusIssue.message));
        //                     }
        //                     serviceStatus.statuses.push(status);
        //                 }
        //
        //                 // console.log(serviceStatus);
        //                 this.setState({
        //                     isLoading: false, serviceStatus: serviceStatus
        //                 });
        //             }
        //             this.intervalID = setTimeout(this.getData.bind(this), 30000);
        //         }, // Note: it's important to handle errors here
        //         // instead of a catch() block so that we don't swallow
        //         // exceptions from actual bugs in components.
        //         (error) => {
        //             this.setState({
        //                 isLoading: false, error
        //             });
        //             this.intervalID = setTimeout(this.getData.bind(this), 30000);
        //         })
    }

    // componentDidUpdate() {
    //     ReactTooltip.rebuild();
    // }

    render() {
        //const title = 'Category title';
        // const {error, isLoaded, serviceStatus} = this.state;

        let classes: string[] = [];
        if (this.isSubCategory) {
            classes.push('subcategory');
        }
        if (this.isDummy) {
            classes.push('loading-dummy');
        }
        if (this.state.error) {
            classes.push('error');
        }

        // } else {
        const tip: string = this.categoryDescription + (this.state.serviceStatus === null ? '' : ' <div>Last fetched: ' + this.state.serviceStatus.fetched.toLocaleDateString('en-GB') + '</div>');


        return <div className={'status-row ' + classes.join(' ')}>
            <div className="wrapper">
                <div className="container">
                    <div className="label">
                        <h3 className="title loading-block" data-tip={tip} data-for={this.categoryCode}>
                            {this.categoryTitle}
                        </h3>
                    </div>

                </div>
            </div>
        </div>;

    }

    renderStatuses(serviceStatus: ServiceStatus, limit: number): string {
        const result: [] = [];

        let length: number = 0;
        if (serviceStatus !== null) {
            length = serviceStatus.statuses.length;
        }
        let i: number = 0;
        if (length < limit) {
            for (let k = 0; k < limit - length; ++k) {
                result.push(<div key={i} className={"flag-status unknown"}></div>);
                i++;
            }
        }
        if (serviceStatus !== null) {
            serviceStatus.statuses.forEach((status: Status) => {
                let toolTip = '<div class="date">' + status.date.toLocaleDateString('en-GB', {timeZone: 'GMT'}) + '</div>';

                if (status.status !== 'online') {
                    for (let statusIssue of status.issues) {
                        const duration = statusIssue.end.getTime() - statusIssue.start.getTime();
                        let message = '';
                        if (statusIssue.message !== null) {
                            message = statusIssue.message;
                        }
                        toolTip += '<div>' + message + ' (' + this.millisToMinutesAndSeconds(duration) + ')</div>';
                    }
                } else {
                    toolTip += '<div>No downtime recorded</div>';
                }

                //if (status.message !== null && status.message !== undefined && status.message !== '') {
                // toolTip += '<div>' + status.date + '</div>';
                //}


                result.push(<div key={i} className={"flag-status " + status.status + ""}
                                 data-tooltip-html={toolTip} data-tooltip-id={"my-tooltip"}></div>);
                i++;
            });
        }


        return result;
    }

    // componentDidUpdate(prevProps) {
    //
    //     console.log('REbuild tooltip');
    //
    //
    //     setTimeout(() => {
    //         // ReactTooltip.rebuild();
    //     }, 5)
    //
    // }
}

export default StatusCategory;
