export class StatusIssue {

    id: string;
    start: Date;
    end: Date;
    status: string = 'unknown';
    message: string = '';

    constructor(id: string, start: Date, end: Date, status: string, message: string) {
        this.id = id;
        this.start = start;
        this.end = end;
        this.status = status;
        this.message = message;
    }
}
