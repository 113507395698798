import {StatusIssue} from "./StatusIssue";

export class Status {
    status: string = 'unknown';
    latestStatus: string = 'unknown';
    date: Date;
    issues: StatusIssue[];

    constructor(status: string, date: Date) {
        this.status = status;
        this.date = date;
        this.issues = [];
    }
}
