import {Status} from "./Status";

export class ServiceStatus {
    fetched: Date = new Date();
    statusText: string = '-';
    status: string = 'unknown';
    statuses: Status[] = [];

    constructor() {
        this.statuses = [];
    }
}
