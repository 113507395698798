import './App.scss';
import React from 'react';
import Categories from "./Component/Categories/Categories";
import {Tooltip} from "react-tooltip";
import {ReactComponent as ReactLogo} from './logo.svg';
import Alerts from "./Component/Alerts/Alerts";

class App extends React.Component {

    render() {
        return (

            <div>
                <Tooltip id="my-tooltip" place="top" type="dark" effect="solid" style={{zIndex: 99}}/>
                <div className="app-container">

                    <div className="site-header">
                        <div className="logo"><ReactLogo/></div>

                        <div className="text">Attlaz Status Page</div>
                    </div>
                    <div className="block intro">
                        <div className="wrapper">
                            <div className="container">
                                <h1>Current Information on Service Availability.</h1>
                                <div className="description">Below you'll find status information for each of the Attlaz
                                    products and services.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block">
                        <Alerts></Alerts>
                    </div>
                    <div className="block">
                        <div className="legend-container">
                            <h3 className="title">Availability per service</h3>
                            <div className="legend">
                                <span className="status online">Online</span>
                                <span className="status degraded">Partial Degradation</span>
                                <span className="status offline">Offline</span>
                            </div>
                        </div>
                        <Categories></Categories>

                    </div>
                </div>

            </div>

        )
            ;
    }
}

export default App;
