import React from 'react';
import './Alerts.scss';
import {Alert} from "../../Model/Alert";


class Alerts extends React.Component {

    intervalID;

    constructor(props) {
        super(props);
        this.state = {
            error: null, isLoading: false, alerts: []
        };

    }

    getData = () => {
        this.setState({
            isLoading: true
        });
        fetch("https://status.attlaz.com/backend/alerts")
            .then(res => res.json())
            .then((result) => {
                    if (result === null || result === undefined) {
                        console.error('Unable to load alerts (response empty)');
                        this.setState({
                            isLoading: false, error: new Error('Unable to load')
                        });
                    } else {


                        this.setState({
                            isLoading: false, alerts: result.alerts
                        });
                    }

                    this.intervalID = setTimeout(this.getData.bind(this), 30000);

                }, // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoading: false, error
                    });
                    this.intervalID = setTimeout(this.getData.bind(this), 30000);
                });
    };

    componentDidMount() {
        this.getData();
    }

    componentWillUnmount() {
        /*
          stop getData() from continuing to run even
          after unmounting this component. Notice we are calling
          'clearTimeout()` here rather than `clearInterval()` as
          in the previous example.
        */
        clearTimeout(this.intervalID);
    }

    render() {
        let classes: string[] = [];
        if (this.state.error) {
            return '<div></div>';
        } else if (this.state.isLoading) {
            classes.push('loading');
        }
        return <div className={'messages-wrapper ' + classes.join(' ')}>
            {this.state.alerts.map((alert: Alert, i: number) => {

                return <div key={alert.id} className={'alert-row ' + alert.level}>
                    <div className="wrapper">
                        <div className="container">

                            <div className="head">
                                <h3 className="title">
                                    {alert.title}
                                </h3>
                                <div className="categories">
                                    {alert.categories.join(', ')}
                                </div>
                            </div>

                            <div className="messages">
                                {alert.messages.map((message, i) => {
                                    return <div key={message.id} className="message">

                                        <div className="content">
                                                 <span className="title">
                                                {message.title}&nbsp;-&nbsp;
                                            </span>
                                            {message.content}
                                        </div>
                                        <div className="posted">
                                            Posted: {new Date(message.posted).toLocaleString('en-GB')}
                                        </div>
                                    </div>
                                })}

                            </div>


                        </div>
                    </div>
                </div>
                    ;

            })}
        </div>;

    }
}

export default Alerts;
