import React from 'react';
import './Categories.scss';
import StatusCategory from "../StatusCategory/StatusCategory";
import ServiceView from "../ServiceView/ServiceView";

class Categories extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null, isLoading: false, categories: []
        };

    }


    componentDidMount() {
        this.setState({
            isLoading: true
        });
        fetch("https://status.attlaz.com/backend/category/")
            .then(res => res.json())
            .then((result) => {
                    if (result === null || result === undefined) {
                        console.error('Unable to load categories (response empty)');
                        this.setState({
                            isLoading: true, error: new Error('Unable to load')
                        });
                    } else {


                        this.setState({
                            isLoading: false, categories: result
                        });
                    }

                }, // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoading: false, error
                    });
                })
    }

    render() {
        //const title = 'Category title';
        const {error, isLoading, categories} = this.state;
        if (error) {
            return <div className="categories">
                <div className="container">
                    <div className="error">Failure while loading status: {error.message}</div>
                </div>
            </div>;
        } else if (isLoading) {
            return <div className="categories loading">
                <StatusCategory key="dummy1" categoryCode="dummy" categoryTitle="Loading" categoryDescription=""
                                isDummy={true}></StatusCategory>
                <StatusCategory key="dummy2" categoryCode="dummy" categoryTitle="Loading" categoryDescription=""
                                isDummy={true}></StatusCategory>
                <StatusCategory key="dummy3" categoryCode="dummy" categoryTitle="Loading" categoryDescription=""
                                isDummy={true}></StatusCategory>
                <StatusCategory key="dummy4" categoryCode="dummy" categoryTitle="Loading" categoryDescription=""
                                isDummy={true}></StatusCategory>
                <StatusCategory key="dummy5" categoryCode="dummy" categoryTitle="Loading" categoryDescription=""
                                isDummy={true}></StatusCategory>

            </div>;
        } else {
            return <div className="categories">
                {categories.map((category: {
                    id: string,
                    title: string,
                    description: string,
                    services: { id: string; title: string; description: string }[]
                }, i: number) => {

                    const result: [] = [];
                    result.push(<StatusCategory key={"cv_" + category.id} categoryCode={category.id}
                                                categoryTitle={category.title}
                                                categoryDescription={category.description}></StatusCategory>)
                    category.services.forEach((service: { id: string; title: string; description: string }) => {
                        result.push(<ServiceView key={"sv_" + service.id} categoryCode={service.id}
                                                 categoryTitle={service.title}
                                                 categoryDescription={service.description}></ServiceView>)
                    })
                    return result;

                })}
            </div>;
        }
    }
}

export default Categories;
